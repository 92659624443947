import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CameraStorageService {
    #lastUsedDevice: MediaDeviceInfo | undefined;

    public setLastUsedDevice(camera: MediaDeviceInfo): void {
        this.#lastUsedDevice = camera;
    }

    public getLastUsedDevice(): MediaDeviceInfo | undefined {
        return this.#lastUsedDevice;
    }
}
