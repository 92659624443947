import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    NgModule,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ActivityState, Operation, TaskStatus } from '@data-terminal/shared-models';
import { HdmuiComponentsModule } from '@heidelberg/hdmui-angular';
import { StatusIconModule } from './status-icon/status-icon.pipe';
import { DateFormatterWithoutYear } from '@data-terminal/utils';
import { OperationStatePipe } from './operation-state/operation-state.pipe';
import { TaskPilotInformationComponent } from './task-pilot-information/task-pilot-information.component';

@Component({
    selector: 'data-terminal-operation-card',
    templateUrl: './operation-card.component.html',
    styleUrls: ['./operation-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationCardComponent implements OnChanges {
    @Input() public operation!: Operation;
    @Input() public attrOrder!: string[];
    @Input() public isSelected = false;
    @Input() public runningOnOtherMachine = false;
    @Input() public hasTaskInformation = false;
    @Output() public cardSelect = new EventEmitter<Operation>();

    public shouldShowTaskPilotInformation = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.operation) {
            this.shouldShowTaskPilotInformation = !!this.operation?.tasks?.some(
                (task) =>
                    task.status === TaskStatus.active ||
                    task.status === TaskStatus.close_ok ||
                    task.status === TaskStatus.close_timeout
            );
        }
    }

    public onCardSelect(): void {
        this.cardSelect.next(this.operation);
    }

    public isCompleted(): boolean {
        return this.operation.opState === ActivityState.COMPLETED;
    }

    public isTimeExceeded(): boolean {
        return (
            this.operation.opPlannedStart < new Date().getTime() &&
            this.operation.opPlannedStart !== 0 &&
            this.operation.opState !== ActivityState.COMPLETED
        );
    }

    protected readonly Number = Number;
}

@NgModule({
    declarations: [OperationCardComponent],
    exports: [OperationCardComponent],
    imports: [
        HdmuiComponentsModule,
        CommonModule,
        MatIconModule,
        MatCardModule,
        StatusIconModule,
        TranslateModule,
        MatProgressBarModule,
        MatTooltipModule,
        DateFormatterWithoutYear,
        OperationStatePipe,
        TaskPilotInformationComponent,
    ],
})
export class OperationCardModule {}
