import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TaskPilotDisplayData, TaskPilotInformationService } from '../../../services/task-pilot-information.service';
import { HdmuiIconsModule } from '@heidelberg/hdmui-angular';
import { ClosedTasksCountPipe } from '../closed-tasks-count/closed-tasks-count.pipe';
import { Task } from '@data-terminal/shared-models';

@Component({
    selector: 'data-terminal-task-pilot-information',
    standalone: true,
    imports: [ClosedTasksCountPipe, MatIconModule, HdmuiIconsModule],
    templateUrl: './task-pilot-information.component.html',
    styleUrl: './task-pilot-information.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPilotInformationComponent implements OnChanges {
    @Input() tasks: Task[] = [];
    displayData: TaskPilotDisplayData | undefined;

    readonly #taskPilotInformationService = inject(TaskPilotInformationService);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tasks) {
            this.displayData = this.#taskPilotInformationService.prepareTaskPilotDataToDisplay(this.tasks);
        }
    }
}
