import { Pipe, PipeTransform } from '@angular/core';
import { Task, TaskStatus } from '@data-terminal/shared-models';

@Pipe({
    standalone: true,
    name: 'closedTasksCount',
})
export class ClosedTasksCountPipe implements PipeTransform {
    transform(tasks: Task[]): string {
        const closedTasksCount: number = tasks.reduce((acc, task) => {
            if (task.status === TaskStatus.close_ok) {
                acc += 1;
            }
            return acc;
        }, 0);
        return `${closedTasksCount}/${tasks.length}`;
    }
}
