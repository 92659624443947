import { Injectable } from '@angular/core';
import { ItemType, Task, TaskStatus } from '@data-terminal/shared-models';

export interface TaskPilotDisplayData {
    semiFinishedTasks: Task[];
    toolTask: Task | undefined;
    statusIconForToolTask: string | undefined;
    statusIconForSingleSemiFinishTask: string | undefined;
    isTimeoutIconVisibleForMultipleSemiFinishTasks: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class TaskPilotInformationService {
    public prepareTaskPilotDataToDisplay(taskList: Task[] | undefined): TaskPilotDisplayData | undefined {
        if (!taskList || !taskList?.length) {
            return undefined;
        }

        const semiFinishedTasks = taskList?.filter((task) => task.itemType === ItemType.Semifinish) ?? [];
        const toolTask = taskList?.find((task) => task.itemType === ItemType.Tool);
        let statusIconForToolTask;
        let statusIconForSingleSemiFinishTask;

        if (toolTask) {
            statusIconForToolTask = this.getStatusIconForSingleTask(toolTask);
        }

        if (semiFinishedTasks.length === 1) {
            statusIconForSingleSemiFinishTask = this.getStatusIconForSingleTask(semiFinishedTasks[0]);
        }

        const isTimeoutIconVisibleForMultipleSemiFinishTasks = this.isTimeoutIconVisibleForTaskList(semiFinishedTasks);

        return {
            semiFinishedTasks,
            toolTask,
            statusIconForToolTask,
            statusIconForSingleSemiFinishTask,
            isTimeoutIconVisibleForMultipleSemiFinishTasks,
        };
    }

    private getStatusIconForSingleTask(task: Task): string | undefined {
        if (task.status === TaskStatus.active) {
            return 'hdmui:clock';
        }

        if (task.status === TaskStatus.close_ok) {
            return 'hdmui:hookCircled';
        }

        if (task.status === TaskStatus.close_timeout) {
            return 'hdmui:xCircled';
        }
        return undefined;
    }

    private isTimeoutIconVisibleForTaskList(tasks: Task[]): boolean {
        return tasks.length > 1 && tasks.some((task) => task.status === TaskStatus.close_timeout);
    }
}
