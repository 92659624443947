<hdmui-base-dialog
    class="barcode-scan__dialog"
    dialogIcon="hdmui:rteAlignmentJustify"
    [dialogTitle]="'DC.BARCODESCANDIALOG.TITLE' | translate"
    [dialogOptions]="OPTION_NO_BUTTON"
    (okClicked)="onManualActionClick()"
>
    <div class="barcode-scan__dialog--header">
        @if (!isLoading && devices.length) {
            <mat-form-field (click)="changeCamera()">
                <mat-label
                    >{{
                        (devices.length === 1
                            ? 'DC.BARCODESCANDIALOG.CAMERASELECT.FIXED_CAMERA'
                            : 'DC.BARCODESCANDIALOG.CAMERASELECT.LABEL'
                        ) | translate
                    }}
                </mat-label>
                <input
                    class="camera__button"
                    matInput
                    [value]="selectedDevice?.label"
                    placeholder="DC.BARCODESCANDIALOG.CAMERASELECT.LABEL"
                    readonly
                    [disabled]="devices.length === 1"
                />
                <mat-icon matSuffix svgIcon="hdmui:cameraSwitch"></mat-icon>
            </mat-form-field>
        }
        @if (isTorchCompatible) {
            <mat-button-toggle [checked]="isTorchEnabled" (change)="toggleFlashlight()" class="torch-button hdmui-ms-3">
                <mat-icon [svgIcon]="flashlightIcon"></mat-icon>
            </mat-button-toggle>
        }
    </div>

    <div class="scanner__wrapper" [class.no-devices]="isLoading || !devices.length">
        <div class="scanner__overlay" #overlay></div>

        <div #scanner>
            <zxing-scanner
                [torch]="isTorchEnabled"
                [formats]="ALLOWED_FORMATS"
                [device]="selectedDevice"
                (torchCompatible)="onTorchCompatible($event)"
                (camerasFound)="camerasFoundHandler($event)"
                (camerasNotFound)="camerasNotFoundHandler()"
                (scanSuccess)="scanSuccessHandler()"
                (scanError)="scanErrorHandler($event)"
                (scanComplete)="scanCompleteHandler($event)"
                (permissionResponse)="onPermissionsResponse($event)"
            ></zxing-scanner>
        </div>
    </div>

    @if (isLoading) {
        <data-terminal-loading-indicator></data-terminal-loading-indicator>
    } @else if (!devices.length) {
        <hdmui-empty-states icon="hdmui:warning" class="hdmui-my-3">
            <hdmui-empty-states-title>{{ emptyStatesTitle }}</hdmui-empty-states-title>
            <hdmui-empty-states-description>{{ emptyStatesDescription }}</hdmui-empty-states-description>
        </hdmui-empty-states>
    } @else {
        <p class="hdmui-caption hdmui-my-4 hdmui-py-4">
            {{ 'DC.BARCODESCANDIALOG.DESCRIPTION' | translate }}
        </p>
    }

    <div class="barcode-scan__dialog--buttons">
        <button mat-stroked-button (click)="onManualActionClick()" class="hdmui-ms-0">
            <mat-icon [svgIcon]="inputType === BarcodeScanDialogType.SEARCH ? 'hdmui:search' : 'hdmui:edit'"></mat-icon>
            {{
                (inputType === BarcodeScanDialogType.SEARCH ? 'DC.ACTION.MANUALSEARCH' : 'DC.ACTION.MANUALINPUT')
                    | translate
            }}
        </button>
    </div>
</hdmui-base-dialog>
