<div class="hdmui-content task-pilot-information">
    @if (displayData?.semiFinishedTasks?.length; as semiFinishedTasksLength) {
        <div class="task-pilot-information__semifinished-goods">
            <mat-icon svgIcon="hdmui:palletHigh"></mat-icon>
            @if (semiFinishedTasksLength && semiFinishedTasksLength > 1) {
                <div class="hdmui-font-small">
                    {{ displayData?.semiFinishedTasks ?? [] | closedTasksCount }}
                </div>
                @if (displayData?.isTimeoutIconVisibleForMultipleSemiFinishTasks) {
                    <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
                }
            } @else {
                <mat-icon [svgIcon]="displayData?.statusIconForSingleSemiFinishTask ?? ''"></mat-icon>
            }
        </div>
    }
    @if (displayData?.toolTask) {
        <div class="task-pilot-information__tools">
            <mat-icon svgIcon="hdmui:wrench"></mat-icon>
            <mat-icon [svgIcon]="displayData?.statusIconForToolTask ?? ''"></mat-icon>
        </div>
    }
</div>
